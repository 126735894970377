import styled from "styled-components";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  h1 {
    font-size: 2.6rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.5rem;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.6rem;
  }

  p:not(:last-of-type) {
    margin: 0 0 1rem;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  blockquote {
    border-left: 5px solid #eee;
    font-style: italic;
    margin: 1rem 0;
    padding: 1rem 2rem;
  }

  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    list-style-position: inside;
    margin: 0 0 1rem;

    ul {
      list-style-type: circle;
      margin-left: 15px;
    }
    ol {
      list-style-type: lower-latin;
      margin-left: 15px;
    }

    div {
      display: inline;
    }
  }

  @media (min-width: 640px) {
    h1 {
      font-size: 3.5rem;
    }
  }
`;

const HTMLIndex = ({ content, ...props }) => {
  if (!content) return null;

  return (
    <Content
      {...props}
      dangerouslySetInnerHTML={{
        __html: HTMLOutput(content),
      }}
    />
  );
};

export default HTMLIndex;

export function HTMLOutput(content) {
  return htmlDecode(stateToHTML(convertFromRaw(JSON.parse(content))));
}

function htmlDecode(str) {
  return str
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&amp;/g, "&");
}
