import React from "react";
import styled from "styled-components";
import HTML from "components/HTML";
import { Container } from "components/Grid";

const Content = styled.div`
  padding: 2rem 0;
  font-size: 1.8rem;
  color: #272727;
  background-color: #f5f5f5;
  text-align: center;

  @media (min-width: 640px) {
    padding: 4rem 0;
    font-size: 2rem;
  }
`;

const ContentIndex = ({ content }) => (
  <Content>
    <Container>
      <HTML content={content} />
    </Container>
  </Content>
);

export default ContentIndex;
