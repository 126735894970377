import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "components/Grid";
import QuizTile from "components/QuizTile";
import { useUser } from "lib/user/context";
import usePopularQuizzes from "./usePopularQuizzes";

const Wrapper = styled.section`
  padding: 3rem 0 0;
`;

const Title = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const Column = styled(Col)`
  flex: 0 1 100%;

  @media (min-width: 480px) {
    flex: 0 1 50%;
  }

  @media (min-width: 768px) {
    flex: 0 1 33.33%;
  }
`;

const PopularQuizzes = () => {
  const user = useUser();
  const quizzes = usePopularQuizzes(user);

  if (!quizzes.length) return null;

  return (
    <Wrapper>
      <Container>
        <Title>Popular Quizzes</Title>

        <Row justifyContent="center">
          {quizzes.map((quiz) => (
            <Column key={`popular-${quiz.id}`}>
              <QuizTile {...quiz} />
            </Column>
          ))}
        </Row>
      </Container>
    </Wrapper>
  );
};

export default PopularQuizzes;
