import Head from "next/head";
import styled from "styled-components";
import Format from "components/Format";
import ExploreButtons from "./ExploreButtons";

const Hero = styled.section`
  position: relative;
  min-height: 70vh;
  display: flex;
  align-items: center;
  text-align: center;
  background: #333 center center no-repeat;
  background-size: cover;
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : "none"};

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(139, 139, 139, 0.77);
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1 100%;
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  color: #fff;
  padding: 6rem 1.5rem 2rem;

  h1 {
    margin-bottom: 2rem;
    font-size: 3.5rem;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: normal;

    &:last-child {
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 480px) {
    h1 {
      font-size: 3.6rem;
    }
  }

  @media (min-width: 640px) {
    h1 {
      font-size: 6vw;
    }

    h2 {
      font-size: 2rem;
    }
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 7rem;
    }

    h2 {
      font-size: 2.4rem;
    }
  }
`;

const HeroIndex = (props) => (
  <>
    <Head>
      <link rel="preload" href={props.backgroundImage} as="image" />
    </Head>

    <Hero backgroundImage={props.backgroundImage}>
      <Content>
        <Format tag="h2">{props.subTitle}</Format>
        <h1>{props.title}</h1>

        <ExploreButtons />
      </Content>
    </Hero>
  </>
);

export default HeroIndex;
