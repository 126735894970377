import React, { useState } from "react";
import styled from "styled-components";
import Button from "components/Button";
import { Row, Col } from "components/Grid";

const Container = styled.div`
  position: relative;
`;

const MainButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
`;

const Title = styled.div`
  flex: 1 1 100%;
  margin-bottom: 1rem;
  font-size: 2rem;

  @media (min-width: 640px) {
    margin-bottom: 3rem;
  }
`;

const ButtonCol = styled(Col)`
  flex: 1 1 100%;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    flex: 0 1 33.33%;
    max-width: 33.33%;
    margin-bottom: 0;
  }
`;

const ExploreButtons = () => {
  const [explore, setExplore] = useState(false);

  const handleExplore = () => setExplore(true);

  return (
    <Container>
      <MainButton show={!explore}>
        <Button onClick={handleExplore}>Explore Quizzes</Button>
      </MainButton>

      <Buttons show={explore}>
        <Title>Select a category:</Title>
        <Row>
          <ButtonCol>
            <Button href="/[category]" as="/electronics" secondary>
              Electronics
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button href="/[category]" as="/sports-and-fitness" secondary>
              Sports
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button href="/[category]" as="/home-and-garden" secondary>
              Home
            </Button>
          </ButtonCol>
        </Row>
      </Buttons>
    </Container>
  );
};

export default ExploreButtons;
