import React from "react";
import Head from "next/head";
import isEmpty from "lodash/isEmpty";
import Layout from "components/Layout";
import Hero from "components/home/Hero";
import Content from "components/home/Content";
import PopularQuizzes from "components/home/PopularQuizzes";
import { getByID as getPage } from "lib/models/Page";
import withGlobalFields from "lib/withGlobalFields";

const Index = (props) => {
  const { title, subTitle, image, text, seo } = props.fields;

  return (
    <Layout {...props.global}>
      <Head>
        {!isEmpty(seo.title) && <title>{seo.title}</title>}
        {!isEmpty(seo.title) && (
          <meta name="description" content={seo.description} />
        )}
      </Head>

      <Hero title={title} subTitle={subTitle} backgroundImage={image} />

      <Content content={text} />

      <PopularQuizzes />
    </Layout>
  );
};

export const getStaticProps = withGlobalFields(async () => {
  const { fields } = await getPage("home");

  return { props: { fields } };
});

export default Index;
