import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { storage } from "lib/firebase";

const Tile = styled.a`
  display: block;
  position: relative;
  margin-bottom: 3rem;
  overflow: hidden;
  padding-top: 70%;
  text-decoration: none;
  background-color: #eee;

  &::after {
    content: " ";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const ImageWrap = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-out;
  opacity: ${(props) => {
    switch (props.state) {
      case "entering":
      case "entered":
        return 1;
      default:
        return 0;
    }
  }};
`;

const Content = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.h3`
  margin: 0;
  font-size: 2.4rem;
`;

const QuizTile = (props) => {
  const isMounted = useRef(true);
  const [image, setImage] = useState(null);

  const fetchImage = async () => {
    const ref = storage.ref().child(`quizzes/${props.id}`);

    const src = await ref.getDownloadURL();
    if (isMounted.current) setImage(src);
  };

  useEffect(() => {
    if (props.image) fetchImage();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Link href="/quiz/[slug]" as={`/quiz/${props.slug}`} passHref>
      <Tile>
        <Transition
          in={!!image}
          timeout={400}
          mountOnEnter
          onEnter={(node) => node.offsetHeight}
        >
          {(state) => {
            return (
              <ImageWrap state={state}>
                <Image
                  src={image}
                  alt={props.name}
                  width={400}
                  height={300}
                  objectFit="cover"
                />
              </ImageWrap>
            );
          }}
        </Transition>

        <Content>
          <Name>{props.name}</Name>
        </Content>
      </Tile>
    </Link>
  );
};

export default QuizTile;
