import React from "react";

const Format = ({ children, tag = "p" }) => {
  if (typeof children !== "string") return null;

  let result = children
    .replace(/\n{2}/g, `</${tag}><${tag}>`)
    .replace(/\n/g, "<br />");

  result = `<${tag}>${result}</${tag}>`;

  return <div dangerouslySetInnerHTML={{ __html: result }} />;
};

export default Format;
