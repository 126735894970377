import { useState, useEffect } from "react";
import { getPopularQuizzes } from "lib/models/Quiz";

const usePopularQuizzes = (user) => {
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const quizzes = await getPopularQuizzes(
        ["name", "slug", "image"],
        user.country,
        6
      );

      setQuizzes(quizzes);
    };

    if (user) fetchData();
  }, [user]);

  return quizzes;
};

export default usePopularQuizzes;
